
@if(isBrowser && photoUrl){
  <div id="lightGallery" class="hidden-images-array">
    <lightgallery [settings]="settings">
      <a href="{{ photoUrl }}">
        <img
          className="img-responsive"
          alt="profile-image"
          src="{{ photoUrl }}"
        />
      </a>
      
    </lightgallery>
  </div>
  }

  <div class="photo-and-labels" [class.big]="big" [class.small]="small">
    @if(showImage){
      <div class="profile-photo-img-wrapper">
        @if(role == Role.admin){
          <div class="profile-placeholder" pRipple>
             QL 
          </div>
        } @else {
          @if (!photoUrl) {
            <div class="profile-placeholder" [class.success]="successPlaceholderBackground" pRipple>
              {{ (firstName ? firstName?.charAt(0) : '') + (lastName ? lastName?.charAt(0) : '') }}
            </div>
          } @else {

            <img (click)="galleryEnabled && openLightGallery()" class="profile-image" [src]="photoUrl" alt="user-image">
          }
        }
      </div>
    }
    
    @if (showActions) {
      @if(previewOnly){
        <div class="photo-actions">
          <span (click)="openLightGallery()" class="upload-text" pRipple>Preview image</span>
        </div>
      } @else {
        <div class="photo-actions">
          <span class="upload-text" [class.disabled]="actionsDisabled" pRipple>Upload photo</span>
          @if (photoUrl) {
            <span class="remove-text" [class.disabled]="actionsDisabled" pRipple>Remove photo</span>
          }
        </div>
      }
    }
    @if(showUsername || showRole){
      <div class="text-content" [style.max-width]="maxNameWidth">
        @if (showUsername) {
  
          <h3 class="profile-name mb-unset"  
          [class.nowrap]="showRole" [class.mb-4-px]="showRole">
          @if(role && role == Role.admin){
            Qualylab
          } @else {
            {{firstName}} {{lastName}}
          }
          </h3>
        }
        @if (showRole) {
          <h4 class="role mb-unset">{{role}}</h4>
        }
      </div>
    }
  
  </div>

