import { Component, inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';

import { RippleModule } from 'primeng/ripple';
import { AuthService } from '../../models/auth/auth.service';
import { StateService } from '../../services/state.service';
import { Role } from '../../interfaces/global.enums';
import { Router } from '@angular/router';
import { LightgalleryModule } from 'lightgallery/angular';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import lgThumbnails from 'lightgallery/plugins/thumbnail';
import lgFullScreen from 'lightgallery/plugins/fullscreen';
import { isPlatformBrowser } from '@angular/common';
import { LightGallerySettings } from 'lightgallery/lg-settings';

@Component({
  selector: 'app-user-image-block',
  standalone: true,
  imports: [RippleModule, MenuModule, ButtonModule, LightgalleryModule],
  templateUrl: './user-image-block.component.html',
  styleUrl: './user-image-block.component.scss'
})
export class UserImageBlockComponent implements OnInit {
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() role: string;
  @Input() photoUrl: string;

  @Input() previewOnly: boolean;
  @Input() maxNameWidth: string;
  @Input() showImage: boolean = true;
  @Input() showActions: boolean = false;
  @Input() actionsDisabled: boolean = false;
  @Input() showUsername: boolean = false;
  @Input() showRole: boolean = false;
  @Input() galleryEnabled: boolean = true;
  @Input() big: boolean = false;
  @Input() small: boolean = false;
  @Input() successPlaceholderBackground: boolean;
  public Role = Role;

  @Input() images: any[] = [];
  public settings: LightGallerySettings = {
    licenseKey: 'F8E8CA05-4E18-4D6D-BD4D-6AD1AF77B0C0',
    counter: false,
    rotate: true,
    zoom: true,
    plugins: [lgZoom, lgRotate, lgThumbnails, lgFullScreen],
  };
  public isBrowser: boolean = false;
  private platformId: {};
  
  constructor(private stateService: StateService, private router: Router, private authService: AuthService){
    this.platformId = inject(PLATFORM_ID);
  }

  ngOnInit(): void {
    this.isBrowser = isPlatformBrowser(this.platformId)
  }


  public openLightGallery(i: number = 0) {
    const $dynamicGallery = document.getElementById('lightGallery');
    const links = $dynamicGallery!.getElementsByTagName('a');
    if (i !== undefined && i >= 0 && i < links.length) {
      links[i].click();
    }
  }

  
}
